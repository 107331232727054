.CateListItem {
  width: 100%;
  display: flex;
  border-bottom: 1px dashed #707070;
  padding: 10px 0 10px 50%;
  flex-direction: column;
  cursor: pointer;
  @include mobile {
    position: relative;
    min-height: 80px;
    padding-left: 120px;
    padding-right: 20px;
  }
  &:first-child {
    img {
      @include tablet {
        opacity: 1;
      }
      @include mobile {
        opacity: 1;
      }
    }
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
  h3 {
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase;
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      font-size: 16px;
    }
  }
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 300;

    @include mobile {
      font-size: 14px;
    }
  }
  img {
    position: absolute;
    left: 130px;
    top: 5%;
    max-width: 30%;
    width: 100%;
    // max-height: 90%;
    max-height: max-content;
    object-fit: cover;
    opacity: 0;
    z-index: 9;
    transition: $transition1;
    @include mobile {
      max-width: 80px;
      height: calc(100% - 10px);
      top: 5px;
      left: 20px;
      z-index: 4;
    }
  }
}
