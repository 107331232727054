.CaseSingle {
  width: 100%;
  display: flex;
  @include tablet {
    flex-direction: column-reverse;
  }
  @include mobile {
    flex-direction: column-reverse;
  }
  &__left {
    width: 50%;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
  }
  &__right {
    width: calc(50% - 40px);
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    @include tablet {
      width: 100%;
      height: 50vh;
      position: static;
    }
    @include mobile {
      width: 100%;
      height: 50vh;
      position: static;
    }
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      @include tablet {
        height: 100%;
      }
      @include mobile {
        height: 100%;
      }
    }
    .plyr {
      @include tablet {
        height: 100% !important;
      }
      @include mobile {
        height: 100% !important;
      }
    }
    video {
      width: 100%;
      height: 100vh !important;
      object-fit: cover;
      @include tablet {
        height: 100% !important;
      }
      @include mobile {
        height: 100% !important;
      }
    }
  }
  &-left {
    z-index: 1000;
  }
  &-right {
    z-index: 10;
    img {
      height: 100%;
    }
  }

  //Animations
  @keyframes caseSingleAnimation {
    0% {
      opacity: 0;
      // transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      // transform: translateX(0%);
    }
  }

  &.animate-case-single {
    opacity: 0;
    animation: caseSingleAnimation 1s 0.5s forwards;
  }

  @keyframes imageAnimation {
    0% {
      transform: translateX(-40%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes titleAnimation {
    0% {
      opacity: 0.2;
      transform: translateX(50%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  .case-animate-title {
    opacity: 0;
    z-index: 101;
    transform: translateX(40%);
    transition: $transition2;
    animation: titleAnimation 0.7s 1.5s forwards;
  }

  @keyframes descriptionAnimation {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .case-animate-description {
    margin-bottom: 140px;
    opacity: 0;
    animation: descriptionAnimation 1s 2s forwards;
  }

  @keyframes imageWrapperAnimation {
    0% {
      opacity: 0.3;
      width: auto;
      height: 300px;
      transform: translateY(40px) translateX(-50%);
    }

    100% {
      opacity: 1;
      width: auto;
      height: 100%;
      transform: translateY(0) translateX(0);
    }
  }
  .case-animate-image {
    opacity: 0;
    transform: translateY(50px) translateX(-100%);
    width: 300px;
    height: 300px;
    animation: imageWrapperAnimation 1.5s 1.5s forwards;
    transition: $transition2;
  }
}
