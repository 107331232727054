.Page404 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin-bottom: 20px;
    color: #fff;
  }
  a {
    font-size: 18px;
    color: #e30715;
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    transition: all 0.3 ease-in-out;
    &:hover {
      color: #e30715;
      border-bottom-color: #e30715;
    }
  }
}
