@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
  --plyr-color-main: #ff0000;
}
html {
  height: 100%;
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // overflow: hidden;
  // height: 100%;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
}
body {
  background: #282828;
  font-family: 'Montserrat', sans-serif;
  background-image: url('../assets/images/bg_grain.svg');
  background-attachment: fixed;
  height: 100%;
  @include desktop {
    overflow: hidden;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  @include tablet {
    height: auto;
    overflow: auto;
  }
  @include mobile {
    height: auto;
  }
}
.App_wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  @include tablet {
    height: auto;
    overflow: hidden;
  }
  @include mobile {
    height: auto;
    overflow: hidden;
  }
}
.Main {
  padding-left: 60px;
  &::-webkit-scrollbar {
    display: none;
  }
  @include desktop {
    height: 100%;
    overflow-y: auto;
  }
  @include tablet {
    padding-left: 0;
    padding-top: 64px;
  }
  @include mobile {
    padding-left: 0;
    padding-top: 64px;
  }
}
ul {
  margin-bottom: 0;
}
a {
  &:hover {
    text-decoration: none;
  }
}
.pdr-0 {
  padding-right: 0 !important;
  @include tablet {
    padding-right: 10px !important;
  }
  @include mobile {
    padding-right: 10px !important;
  }
}
