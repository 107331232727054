.Sidebar {
  border-right: 1px solid #707070;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background-image: url('../../assets/images/bg_grain.svg');
  transition: all 0.3s ease-in-out;
  @include tablet {
    width: 100%;
    height: auto;
    z-index: 5;
    border-right: 0;
  }
  @include mobile {
    width: 100%;
    height: auto;
    z-index: 5;
    border-right: 0;
  }
  &.hide-from-animation {
    opacity: 0;
  }
  &__Logo {
    display: flex;
    justify-content: center;
    span {
      font-family: 'Migra';
      font-size: 1.6em;
      color: #fff;
      writing-mode: vertical-rl;
      cursor: pointer;
      transform: rotate(180deg);
      @include tablet {
        font-size: 1.4em;
        writing-mode: inherit;
        transform: none;
      }
      @include mobile {
        font-size: 1.4em;
        writing-mode: inherit;
        transform: none;
      }
    }
  }
  &__goback {
    cursor: pointer;
  }
  .Icon {
    width: 30px;
    height: 30px;
    &__wheelr {
      width: 30px;
      height: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: #ff0000;
        transition: $transition1;
      }

      &:hover {
        &:before {
          width: 15px;
          height: 15px;
        }
        span {
          transform: translateY(0) translateX(-50%) rotate(180deg);
          opacity: 1;
        }
      }
      span {
        font-size: 13px;
        color: #fff;
        writing-mode: vertical-rl;
        cursor: pointer;
        transform: translateY(100%) translateX(-50%) rotate(180deg);
        position: absolute;
        left: 50%;
        white-space: nowrap;
        padding-top: 10px;
        bottom: 30px;
        opacity: 0;
        transition: $transition1;
        @include tablet {
          display: none;
        }
        @include mobile {
          display: none;
        }
      }
    }
    @include tablet {
      display: flex;
    }
    @include mobile {
      display: flex;
    }
  }
  &_left {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 9;
    padding: 20px 5px;
    transition: $transition2;
    &.Sidebar--dark,
    &.Sidebar--open {
      background-image: url('../../assets/images/bg_grain_gray.svg');
    }
    @include tablet {
      width: 100%;
      height: auto;
      flex-direction: row;
      padding: 15px 10px;
      border-bottom: 1px solid #707070;
    }
    @include mobile {
      width: 100%;
      height: auto;
      flex-direction: row;
      padding: 15px 10px;
      border-bottom: 1px solid #707070;
    }
    .Hamburger {
      display: block;
      position: relative;
      overflow: hidden;
      width: 30px;
      height: 30px;
      cursor: pointer;
      outline: none;
      transition: background 0.3s;
      z-index: 5;
      transition: transform 0.2s ease-in;
      @include tablet {
        height: 28px;
      }
      @include mobile {
        height: 28px;
      }
      &--toggled {
        @include tablet {
          height: 30px;
        }
        @include mobile {
          height: 30px;
        }
      }
    }

    .Hamburger span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 3px;
      background: #fff;
      transition: background 0.3s;
    }

    .Hamburger span::before,
    .Hamburger span::after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fff;
      transition-duration: 0.3s;
    }

    .Hamburger span::before {
      transition-property: top, transform;
      top: 10px;
    }

    .Hamburger span::after {
      transition-property: bottom, transform;
      bottom: -20px;
    }

    .header_navigation__icon {
      display: none !important;
    }

    /* active state, i.e. menu open */
    .Hamburger--toggled span {
      background: none;
      top: 16px;
      transition: none;
    }

    .Hamburger--toggled span::before {
      top: 0;
      transform: rotate(45deg);
    }

    .Hamburger--toggled span::after {
      bottom: 0;
      transform: rotate(-45deg);
    }

    .Hamburger--toggled span::before,
    .Hamburger--toggled span::after {
      transition-delay: 0s, 0.3s;
    }
  }
  &_wrapper {
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 100%;
    width: calc(100% - 61px);
    background-image: url('../../assets/images/bg_grain_gray.svg');
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 61px;
    z-index: 5;
    transform: translateX(-110%);
    transition: all 0.6s ease-in-out, opacity 0.8s ease-in-out;
    @include tablet {
      width: 100%;
      left: 0;
      padding-top: 65px;
      flex-direction: column;
    }
    @include mobile {
      width: 100%;
      left: 0;
      padding-top: 65px;
      flex-direction: column;
    }
    &.Sidebar--open {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      overflow: visible;
    }
    &__cities {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      @include tablet {
        height: auto;
        margin-top: 20px;
      }
      @include mobile {
        height: auto;
        margin-top: 20px;
      }
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 200px;
        @include tablet {
          padding-left: 0;
        }
        @include mobile {
          padding-left: 0;
        }
      }
      li {
        width: 100%;
        font-family: 'Migra';
        font-size: 9.9vh;
        line-height: 1em;
        color: #fff;
        padding: 5px 80px;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        transition: $transition1;
        @include tablet {
          font-size: 32px;
          padding: 5px 50px;
        }
        @include mobile {
          font-size: 32px;
          padding: 5px 50px;
        }
        span {
          font-family: 'Montserrat', sans-serif;
          font-size: 2.2vh;
          line-height: 1em;
          font-weight: 400;
          position: absolute;
          top: 50%;
          left: 30px;
          transform: translateY(-50%);
          @include tablet {
            font-size: 12px;
            left: 20px;
          }
          @include mobile {
            font-size: 12px;
            left: 20px;
          }
          &:before {
            content: '⬤';
            font-family: 'Montserrat', sans-serif;
            font-size: 10px;
            text-align: center;
            color: #ff0000;
            margin-top: -5px;
            display: block;
            transition: $transition1;
            @include tablet {
              font-size: 8px;
              width: 8px;
              height: 8px;
              margin-bottom: 5px;
              margin-left: 2px;
            }
            @include mobile {
              font-size: 8px;
              width: 8px;
              height: 8px;
              margin-bottom: 5px;
              margin-left: 2px;
            }
          }
        }
        &:hover {
          color: #e30715;
        }
      }
    }
    &__bottom {
      width: 60%;
      color: #fff;
      @include tablet {
        width: 100%;
        margin-top: 30px;
      }
      @include mobile {
        width: 100%;
        margin-top: 30px;
      }
      ul {
        display: flex;
        flex-direction: column;
        @include tablet {
          width: 100%;
          flex-wrap: wrap;
          justify-content: center;
        }
        @include mobile {
          width: 100%;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      li {
        font-family: 'Antonio', sans-serif;
        font-size: 25px;
        line-height: 1.38em;
        text-transform: uppercase;
        cursor: pointer;
        @include tablet {
          font-size: 22px;
          margin-left: 20px;
        }
        @include mobile {
          font-size: 22px;
          margin-left: 20px;
        }
        &:hover {
          color: #e30715;
        }
      }
    }
  }
}
.LanguageSelector {
  margin-top: 23px;
  @include mobile {
    margin-top: 10px;
    flex-direction: row;
    justify-content: flex-start;
  }
  li {
    width: auto !important;
    margin-left: 0 !important;
    @include mobile {
      margin-right: 10px;
    }
    &:last-child {
      width: auto;
    }
  }
  span {
    font-size: 22px;
    line-height: 1.5em;
    color: #fff;
    transition: $transition1;
    cursor: pointer;
    text-transform: uppercase;
    @include tablet {
      font-size: 18px;
    }
    @include mobile {
      font-size: 18px;
    }
    &:hover {
      color: #e30715;
    }
  }
}
