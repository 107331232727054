.MarkerHeader {
  width: calc(100% - 60px);
  position: fixed;
  top: 0;
  left: 60px;
  background: #282828;
  background-image: url('../../assets/images/bg_grain.svg');
  background-attachment: fixed;
  padding: 15px 0;
  border-bottom: 1px solid #707070;
  z-index: 13;
  @include tablet {
    width: 100%;
    top: 64px;
    left: 0;
    overflow: scroll;
    z-index: 11;
  }
  @include mobile {
    width: 100%;
    top: 64px;
    left: 0;
    overflow: scroll;
    z-index: 11;
  }
  ul {
    display: flex;
    justify-content: center;
    @include tablet {
      width: max-content;
      padding: 0 15px;
    }
    @include mobile {
      width: max-content;
      padding: 0 15px;
    }
  }
  li {
    margin-right: 30px;
    font-family: 'Antonio', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    @include tablet {
      font-size: 20px;
    }
    @include mobile {
      font-size: 18px;
    }
    a {
      color: inherit;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
