$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-base: 'Montserrat', sans-serif;

$primary: #58c19e;
$secondary: #ffd72d;

$mq-small: 767px;
$mq-medium: 992px;
$d-small: 1024px;
$d-medium: 1240px;

/*===================================
=            TRANSITIONS            =
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
/*=====  End of TRANSITIONS  ======*/
