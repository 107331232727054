.CaseBox {
  // width: 50%;
  min-width: 400px;
  text-align: center;
  cursor: pointer;
  // margin-top: 50px;
  // margin-bottom: 80px;
  @include tablet {
    min-width: 50%;
  }
  @include mobile {
    min-width: 100%;
  }
  &-dot {
    font-family: 'Migra';
    @include rem('font-size', 31px);
    line-height: em(39, 31);
    color: #ff0000;
    @include mobile {
      font-size: 18px;
    }
  }

  &-title {
    h2 {
      font-family: 'Migra';
      @include rem('font-size', 31px);
      line-height: em(39, 31);
      color: #fff;
      text-transform: uppercase;
      position: relative;
      z-index: 1;
      margin-bottom: 24px;
      @include mobile {
        @include rem('font-size', 24px);
        line-height: em(32, 24);
      }
    }
  }
  &-content {
    img {
      max-width: 300px;
      width: 100%;
      height: 27vh;
      object-fit: cover;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      @include tablet {
        max-width: 350px;
        padding: 0 10px;
        height: 250px;
      }
      @include mobile {
        max-width: 350px;
        padding: 0 10px;
        height: 250px;
      }
    }
    span {
      display: block;
      font-family: 'Antonio', sans-serif;
      @include rem('font-size', 19px);
      color: #ff0000;
      line-height: em(24, 19);
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    h4 {
      font-family: 'Migra';
      @include rem('font-size', 28px);
      line-height: em(39, 28);
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}
