.HorizontalPanel {
  width: 100%;
  max-width: 100%;
  position: relative;
  min-height: calc(100vh - 270px);
  &#zero {
    min-height: auto;
  }
  &.show-after-animation {
    transition: all 0.3s ease-in-out;
    transition-delay: 2s;
    opacity: 0 !important;
  }
  &.is--bottom-pinned:not(#zero),
  &.is--top-pinned:not(#zero) {
    padding-top: 45px;
    .CitiesNav__item {
      width: calc(100% - 60px);
      position: fixed;
      right: 0;
      z-index: 8;
      @include tablet {
        width: 100%;
        position: static;
      }
      @include mobile {
        width: 100%;
        position: static;
      }
    }
  }
  &:nth-child(2) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 0;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 270px;
      }
    }
  }
  &:nth-child(3) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 45px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 225px;
      }
    }
  }
  &:nth-child(4) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 90px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 180px;
      }
    }
  }
  &:nth-child(5) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 135px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 135px;
      }
    }
  }
  &:nth-child(6) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 180px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 90px;
      }
    }
  }
  &:nth-child(7) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 225px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 45px;
      }
    }
  }
  &:nth-child(8) {
    &.is--top-pinned {
      .CitiesNav__item {
        top: 270px;
      }
    }
    &.is--bottom-pinned {
      .CitiesNav__item {
        bottom: 0;
      }
    }
  }
  .CitiesNav__item {
    position: static;
    overflow: visible;
    visibility: visible;
    opacity: 1;
  }
}
.container2 {
  //@include mobile {
  //  padding-bottom: 50px;
  //}
}
.custom_row {
  width: 100%;
  min-height: calc(100vh - 315px);
  display: flex;
  align-items: center;
  .CaseBox {
    @include tablet {
      margin-bottom: 20px;
    }
    @include mobile {
      margin-bottom: 10px;
    }
  }
  .slick-arrow {
    bottom: -35px;
    top: unset;
    z-index: 3;
    // &:before {
    //   display: none;
    // }
    &.slick-prev {
      width: 30px;
      height: 30px;
      left: unset;
      right: 50%;
      transform: translateX(-35px);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 30px;
        height: 4px;
        border-radius: 2.5px;
        background: #fff;
        transition: all 0.15s ease;
        transform-origin: 0 50%;
        opacity: 1;
      }
      &:before {
        transform: translate(0, -1px) rotate(40deg);
      }
      &:after {
        transform: translate(0, 1px) rotate(-40deg);
      }
      &:hover {
        &:before {
          transform: translate(0, -1px) rotate(30deg);
        }
        &:after {
          transform: translate(0, 1px) rotate(-30deg);
        }
      }
      &.slick-disabled {
        opacity: 0.3;
        cursor: default;
        &:before {
          transform: translate(-4px, 0) rotate(0deg);
        }
        &:after {
          transform: translate(-4px, 0) rotate(0deg);
        }
      }
      @include tablet {
        transform: translateX(-35px);
        bottom: -28px;
      }
      @include mobile {
        transform: translateX(-35px);
        bottom: -28px;
      }
    }
    &.slick-next {
      width: 30px;
      height: 30px;
      position: relative;
      left: 50%;
      transform: translateX(35px);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 30px;
        height: 4px;
        border-radius: 2.5px;
        background: #fff;
        transition: all 0.15s ease;
        transform-origin: 100% 50%;
        opacity: 1;
      }
      &:before {
        transform: translate(0, 1px) rotate(40deg);
      }
      &:after {
        transform: translate(0, -1px) rotate(-40deg);
      }
      &:hover {
        &:before {
          transform: translate(0, 1px) rotate(30deg);
        }
        &:after {
          transform: translate(0, -1px) rotate(-30deg);
        }
      }
      &.slick-disabled {
        opacity: 0.3;
        cursor: default;
        &:before {
          transform: translate(4px, 0) rotate(0deg);
        }
        &:after {
          transform: translate(4px, 0) rotate(0deg);
        }
      }
      @include tablet {
        transform: translateX(35px);
        bottom: -28px;
      }
      @include mobile {
        transform: translateX(35px);
        bottom: -28px;
      }
    }
  }
}
.custom-row{
  .slick-arrow {
    bottom: -35px;
    top: unset;
    z-index: 3;
    // &:before {
    //   display: none;
    // }
    &.slick-prev {
      width: 30px;
      height: 30px;
      left: unset;
      right: 50%;
      transform: translateX(-35px);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 30px;
        height: 4px;
        border-radius: 2.5px;
        background: #fff;
        transition: all 0.15s ease;
        transform-origin: 0 50%;
        opacity: 1;
      }
      &:before {
        transform: translate(0, -1px) rotate(40deg);
      }
      &:after {
        transform: translate(0, 1px) rotate(-40deg);
      }
      &:hover {
        &:before {
          transform: translate(0, -1px) rotate(30deg);
        }
        &:after {
          transform: translate(0, 1px) rotate(-30deg);
        }
      }
      &.slick-disabled {
        opacity: 0.3;
        cursor: default;
        &:before {
          transform: translate(-4px, 0) rotate(0deg);
        }
        &:after {
          transform: translate(-4px, 0) rotate(0deg);
        }
      }
      @include tablet {
        transform: translateX(-35px);
        bottom: -58px;
      }
      @include mobile {
        transform: translateX(-35px);
        bottom: -58px;
      }
    }
    &.slick-next {
      width: 30px;
      height: 30px;
      position: relative;
      left: 50%;
      transform: translateX(35px);
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 40%;
        left: 0;
        width: 30px;
        height: 4px;
        border-radius: 2.5px;
        background: #fff;
        transition: all 0.15s ease;
        transform-origin: 100% 50%;
        opacity: 1;
      }
      &:before {
        transform: translate(0, 1px) rotate(40deg);
      }
      &:after {
        transform: translate(0, -1px) rotate(-40deg);
      }
      &:hover {
        &:before {
          transform: translate(0, 1px) rotate(30deg);
        }
        &:after {
          transform: translate(0, -1px) rotate(-30deg);
        }
      }
      &.slick-disabled {
        opacity: 0.3;
        cursor: default;
        &:before {
          transform: translate(4px, 0) rotate(0deg);
        }
        &:after {
          transform: translate(4px, 0) rotate(0deg);
        }
      }
      @include tablet {
        transform: translateX(35px);
        bottom: -58px;
      }
      @include mobile {
        transform: translateX(35px);
        bottom: -58px;
      }
    }
  }
}
.panel {
  min-width: 100vw;
  width: 100%;
  height: 100vh;
  display: flex;
  font-weight: 600;
  font-size: 1.5em;
  position: relative;
  box-sizing: border-box;
  padding: 90px 20px 20px 120px;
}
.single-section {
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 0;
  text-align: center;
  .custom_row {
    @include tablet {
      padding-top: 40px;
    }
    @include mobile {
      padding-top: 40px;
    }
  }
  span {
    @include rem('font-size', 18px);
    color: #ff0000;
    font-weight: 500;
    text-transform: uppercase;
    @include tablet {
      @include rem('font-size', 16px);
    }
    @include mobile {
      @include rem('font-size', 16px);
    }
  }
  h4 {
    font-family: 'Antonio', sans-serif;
    @include rem('font-size', 32px);
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  p {
    @include rem('font-size', 18px);
    color: #ff0000;
    font-weight: 500;
    @include tablet {
      margin-top: 10px;
    }
    @include mobile {
      margin-top: 10px;
    }
  }
  h2 {
    font-family: 'Migra';
    @include rem('font-size', 64px);
    color: #fff;
    text-transform: uppercase;
  }
  &-item {
    width: 100%;
    max-width: 100%;
    padding: 0 100px 0 100px;
    @include tablet {
      padding: 0 10px;
      &:last-child {
        margin-top: 40px;
      }
    }
    @include mobile {
      padding: 0 10px;
      &:last-child {
        margin-top: 40px;
      }
    }
    &-head {
      margin-top: 100px;
      margin-bottom: 50px;
      @include tablet {
        margin-top: 50px;
      }
      @include mobile {
        margin-top: 20px;
      }
    }
    p {
      color: #fff;
      font-weight: 500;
      @include rem('font-size', 16px);
      line-height: 1.6em;
    }
  }
}

.CitiesNav {
  width: calc(100% - 60px);
  transition: all 0.3s ease-in-out;
  transition-delay: 1s;
  &--bottom {
    bottom: 0;
    opacity: 1;
    &.show {
      opacity: 0;
    }
    .CitiesNav__item {
      width: calc(100% - 60px);
      position: fixed;
      z-index: 9;
      @include tablet {
        width: 100%;
        position: static;
      }
      @include mobile {
        width: 100%;
        position: static;
      }
      &:nth-child(7) {
        bottom: 0;
      }
      &:nth-child(6) {
        bottom: 45px;
      }
      &:nth-child(5) {
        bottom: 90px;
      }
      &:nth-child(4) {
        bottom: 135px;
      }
      &:nth-child(3) {
        bottom: 180px;
      }
      &:nth-child(2) {
        bottom: 225px;
      }
      &:nth-child(1) {
        bottom: 270px;
      }
    }
  }
  &--top {
    width: 100%;
    top: 0;
    .CitiesNav__item {
      width: calc(100% - 60px);
      position: fixed;
      z-index: 9;
      @include tablet {
        width: 100%;
        position: static;
      }
      @include mobile {
        width: 100%;
        position: static;
      }
      &:nth-child(1) {
        top: 0;
        border-top: 0;
      }
      &:nth-child(2) {
        top: 45px;
      }
      &:nth-child(3) {
        top: 90px;
      }
      &:nth-child(4) {
        top: 135px;
      }
      &:nth-child(5) {
        top: 180px;
      }
      &:nth-child(6) {
        top: 225px;
      }
      &:nth-child(7) {
        top: 270px;
      }
    }
  }
  &.show-after-animation {
    opacity: 0 !important;
  }
  @include tablet {
    display: none;
  }
  @include mobile {
    display: none;
  }
  &__item {
    width: 100%;
    height: 45px;
    border-top: 1px solid #707070;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    background-image: url('../../assets/images/bg_grain.svg');
    text-decoration: none;
    position: absolute;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    &.show {
      opacity: 1;
      overflow: visible;
      visibility: visible;
    }
    @include tablet {
      padding: 5px 10px;
    }
    @include mobile {
      padding: 5px 10px;
    }

    h4 {
      font-family: 'Antonio', sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: em(22, 22);
      text-transform: uppercase;
      color: #fff;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
    }
    span {
      font-family: 'Antonio', sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: em(22, 22);
      color: #fff;
      text-transform: uppercase;
    }
  }
}
.locked-pin-top {
  .CitiesNav__item {
    // position: fixed;
    left: 0;
    top: 0;
    z-index: 7;
  }
}

.slider_number {
  color: #fff;
  margin: 0 auto;
  display: block;
  width: max-content;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  line-height: em(24, 26);
  padding-top: 50px;
  transition: $transition1;
  @include tablet {
    padding-top: 0;
  }
  @include mobile {
    padding-top: 0;
  }
}
