.Marker {
  width: 100%;
  position: relative;
  // background-color: #d4d4d4;
}

.MarkerCities {
  width: 100%;
  padding-top: 64px;
  &__City {
    font-family: 'Antonio', sans-serif;
    font-size: 32px;
    text-transform: uppercase;
    padding: 10px 50px;
    margin-bottom: 0;
    border-bottom: 1px solid #707070;
    color: #fff;
    @include tablet {
      font-size: 20px;
      padding: 10px 20px;
    }
    @include mobile {
      font-size: 20px;
      padding: 10px 20px;
    }
  }
  &__items {
    width: 100%;
    position: relative;
    // overflow: hidden;
    a {
      color: #fff;
    }
  }
}
