.HeroBanner__outer--mobile {
  @include tablet {
    height: calc(
      100vh - 65px
    ) !important; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100 - 65px) !important;
    padding: 0 !important;
  }
}

.HeroBannerMobile {
  color: #ffffff;

  &-content {
    padding: 15px;
  }

  &-title {
    font-family: 'Migra', serif;
    color: #fff;
    @include rem('font-size', 50px);
    line-height: em(60, 50);
    max-width: 245px;
  }

  &-subtitle {
    font-family: 'Antonio', sans-serif;
    @include rem('font-size', 30px);
  }

  &-scroll {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    .Icon {
      cursor: pointer;
      transform: rotate(-90deg);
    }
  }

  .SliderBanner {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
  }
}

.HeroDescriptionMobile {
  display: none;
  color: #fff !important;
  height: calc(100vh - 64px);

  @include tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-inner {
    padding: 30px;
  }

  &-cities {
  }

  .CitiesNav__item-2 {
    font-family: 'Antonio', sans-serif;
    font-size: 22px;
    font-weight: 500;
    line-height: 1em;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;

    display: flex;
    justify-content: space-between;
    &__index {
      font-family: 'Antonio', sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: 1em;
      color: #fff;
      text-transform: uppercase;
    }
  }

  &-cases {
    .custom_row {
      min-height: auto;
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 19px 13px;
      }

      &-back {
        .Icon {
          display: flex;
          align-items: center;
        }
      }

      &-title {
        font-family: 'Antonio', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 1em;
        text-transform: uppercase;
        color: #fff;
        transition: all 0.3s ease-in-out;
        margin-bottom: 0;
      }

      &--shown {
        height: calc(100vh - 66px);
        //border: 1px solid red;
      }

      &--hidden {
        display: none;
      }
    }
  }

  .HorizontalPanel {
    min-height: auto;
    padding: 0 !important;
  }

  p {
    @include rem('font-size', 15px);
    line-height: em(20px, 15px);
    color: #fff;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.9s;

    &.hide-from-animation {
      opacity: 0;
    }
  }
  h5 {
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
  }
}

.HeroBanner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    flex-direction: column-reverse;
  }

  @include mobile {
    flex-direction: column-reverse;
  }
  &--loader {
    position: fixed;
    align-items: center;
    background: #282828;
    background-image: url('../../assets/images/bg_grain.svg');
    justify-content: center;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    &--active {
      opacity: 1;
      visibility: visible;
      z-index: 9999;
    }
    .HeroBanner__content {
      width: auto;
    }
  }
  &__outer {
    width: 100%;
    height: calc(100vh - 315px);

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-in-out;
    padding: 40px 40px 20px;
    @include tablet {
      height: auto;
      padding: 0 10px;
    }
    @include mobile {
      height: auto;
      padding: 0 10px;
    }
    &--loading {
      // transform: scale(1.3) translateX(calc(100% - 50vw - 300px))
      //   translateY(calc(100vh - 100%));
      @include tablet {
        opacity: 0.2;
        // transform: scale(1) translateX(0);
      }
      @include mobile {
        opacity: 0.2;
        // transform: scale(1) translateX(0);
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
  }
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include tablet {
      height: auto;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 50px;
    }
    @include mobile {
      height: auto;
      flex-direction: column;
      justify-content: flex-end;
      padding-top: 50px;
    }
    h2 {
      font-family: 'Migra';
      // @include rem('font-size', 52px);
      font-size: 4vw;
      line-height: em(52, 52);
      color: #fff;
      margin-bottom: 10px;
      &.hide-from-animation {
        opacity: 0;
        @include tablet {
          opacity: 1;
        }
        @include mobile {
          opacity: 1;
        }
      }
      @include tablet {
        @include rem('font-size', 38px);
        line-height: em(38, 38);
      }
      @include mobile {
        @include rem('font-size', 32px);
        line-height: em(42, 32);
      }
    }
    svg {
      width: 100%;
      max-width: 80%;

      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.5s;
      &.hide-from-animation {
        opacity: 0;
        @include tablet {
          opacity: 1;
        }
        @include mobile {
          opacity: 1;
        }
      }
      text {
        fill: #fff;
      }
    }
    h3 {
      font-family: 'Antonio', sans-serif;
      @include rem('font-size', 49px);
      font-size: 2.5vw;
      line-height: em(49px, 49px);
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 40px;
      b {
        color: #ff0000;
      }
      @include tablet {
        @include rem('font-size', 49px);
        line-height: em(49px, 49px);
      }
      @include mobile {
        @include rem('font-size', 28px);
        line-height: em(28px, 28px);
      }
    }
    &--dsc {
      @include rem('width', 550px);
      @include tablet {
        width: 100%;
        margin-bottom: 70px;
      }
      @include mobile {
        width: 100%;
        margin-bottom: 70px;
      }
      p {
        @include rem('font-size', 13px);
        line-height: em(20px, 13px);
        margin-bottom: 0;
        color: #fff;
        padding-right: 60px;
        margin-bottom: 60px;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.9s;
        &.hide-from-animation {
          opacity: 0;
        }
        @include tablet {
          padding-right: 0;
        }
        @include mobile {
          padding-right: 0;
        }
      }
    }
  }
  h5 {
    font-size: 12px;
    font-style: italic;
    color: #fff;
    text-align: right;
    margin-bottom: 10px;
  }
}
.SliderBanner {
  display: flex;
  width: 31vw;
  align-self: flex-end;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.7s;
  @include tablet {
    width: 400px;
    align-self: flex-end;
    margin-bottom: 50px;
  }
  @include mobile {
    width: 100%;
    align-self: flex-end;
    margin-bottom: 50px;
  }
  &.hide-from-animation {
    opacity: 0;
  }
  .slick-slider {
    width: 100%;
  }
  .slick-slide {
    visibility: hidden;
    &.slick-current {
      visibility: visible;
    }
  }
  &_item {
    width: 100%;
    &:hover {
      h3 {
        color: #ff0000;
      }
    }
    @include tablet {
      padding-right: 0;
    }
    @include mobile {
      padding-right: 0;
    }
    &--thumb {
      width: 100%;
      height: 20vw;
      @include tablet {
        height: 300px;
      }
      @include mobile {
        height: 230px;
      }
      img {
        @extend %imgCropped;
      }
    }
    &--dsc {
      position: relative;
      h3 {
        font-family: 'Migra';
        @include rem('font-size', 28px);
        line-height: em(39, 28);
        color: #fff;
        text-transform: uppercase;
        text-align: right;
        margin-top: 13px;
        margin-bottom: 0;
        transition: $transition1;
        span {
          color: #ff0000;
          @include mobile {
            font-size: 14px;
            position: absolute;
            margin-left: -20px;
          }
        }
        @include tablet {
          @include rem('font-size', 49px);
          line-height: em(49px, 49px);
        }
        @include mobile {
          @include rem('font-size', 28px);
          line-height: em(28px, 28px);
        }
      }
    }
  }
}
