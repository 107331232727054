.AboutPage {
  width: 100%;
  padding: 12vh 8.8vw;
  background-image: url('../../assets/images/bg_grain_gray.svg');
  @include tablet {
    padding: 0 10px;
  }
  @include mobile {
    padding: 0 10px;
  }
  h1 {
    font-family: 'Migra';
    font-size: 4.65vw;
    line-height: 1.12em;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 60px;
    @include mobile {
      font-size: 34px;
      line-height: 1.1em;
      padding-top: 40px;
      margin-bottom: 20px;
    }
    b {
      color: #ff0000;
      font-weight: inherit;
    }
  }
  .row {
    margin-left: -50px;
    margin-right: -50px;
  }
  .col-12 {
    padding-left: 50px;
    padding-right: 50px;
  }
  &__content {
    width: 100%;
    p {
      @include rem('font-size', 15px);
      line-height: 1.3em;
      color: #fff;
      margin-bottom: 20px;
    }
  }
  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 20px;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 50px;
    }
  }
  &__item {
    width: 100%;
    margin-bottom: 20px;

    &--secondary {
      margin-top: 40px;
    }

    h4 {
      font-family: 'Antonio', sans-serif;
      text-transform: uppercase;
      font-size: 24px;
      color: #ff0000;
      margin-bottom: 14px;
      @include mobile {
        font-size: 18px;
      }
    }

    p {
      @include rem('font-size', 14px);
      color: #fff;
      line-height: 1.3em;
      margin-bottom: 0;
      a {
        color: #fff;
      }
    }

    h6 {
      @include rem('font-size', 14px);
      color: #fff;
      font-style: italic;
      line-height: 1.1em;
      margin-bottom: 0;
    }
    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      span {
        line-height: 1.2em;
        @include rem('font-size', 14px);
        color: #fff;
        font-weight: bold;
        font-style: normal;
      }
    }
  }
}
