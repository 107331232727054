@font-face {
  font-family: 'Migra';
  src: url('../assets/fonts/Migra-Extrabold.woff2') format('woff2'),
    url('../assets/fonts/Migra-Extrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Migra';
  src: url('../assets/fonts/MigraItalic-ExtraboldItalic.woff2') format('woff2'),
    url('../assets/fonts/MigraItalic-ExtraboldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Migra';
  src: url('../assets/fonts/Migra-Regular.woff2') format('woff2'),
    url('../assets/fonts/Migra-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Migra';
  src: url('../assets/fonts/Migra-Extralight.woff2') format('woff2'),
    url('../assets/fonts/Migra-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Migra';
  src: url('../assets/fonts/MigraItalic-ExtralightItalic.woff2') format('woff2'),
    url('../assets/fonts/MigraItalic-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
